@import 'src/styles/variables/variables';

@include useTheme();

.Hero {
  position: relative;

  @include min-width(desktop) {
    height: auto;
  }

  .HeroImage {
    width: 100%;
    height: auto;
  }

  .Backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(54, 43, 36, 0.4) 0%, rgba(54, 43, 36, 0.2) 90%);

    @include min-width(medium-tablet) {
      background: linear-gradient(90deg, rgba(54, 43, 36, 0.4) 0%, rgba(54, 43, 36, 0.2) 10%);
    }
  }

  .ContentContainer {
    position: absolute;
    transform: translateY(50%);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    bottom: 55%;
    width: 100%;
    padding-left: 5%;

    @include min-width(medium-tablet) {
      bottom: 47%;
      width: 50%;
      padding-left: 10%;
    }

    @include min-width(desktop) {
    }

    @include min-width(medium-desktop) {
    }

    .Eyebrow {
      white-space: nowrap;
      color: $core2;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 17px;
    }

    .Title {
      @include secondaryFont(400);
      color: $core2;
      font-size: 38px;
      line-height: 42px;
      letter-spacing: -0.4px;
      margin-bottom: 28px;
      max-width: 350px;

      @include min-width(medium-tablet) {
        max-width: 450px;
      }

      @include min-width(desktop) {
        font-size: 58px;
        line-height: 62px;
        margin-bottom: 40px;
      }
    }

    .CTAButton {
      padding: 10px 28px;

      @include min-width(tablet) {
        padding: 0 38px;
      }
    }
  }

}

.AspectRatioBox {
  height: 0;
  overflow: hidden;
  position: relative;
  padding-top: 133.3%;

  @include min-width(medium-tablet) {
    padding-top: 49.5%;
  }
}

.AspectRatioBoxInside {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
